import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons/faLongArrowAltLeft'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons/faLongArrowAltRight'

function PageNavigation({ prevLink, nextLink }) {

    // const [fontSize, setFontSize] = useState(1);

    // useEffect(() => {
    //     try {
    //         var postParagraphs = document.getElementsByClassName("post-content")[0].querySelectorAll("p");
    //         // x.style.fontSize = fontSize + "rem"
    //         postParagraphs.forEach(paragraph => {
    //             paragraph.style.fontSize = fontSize + "rem"
    //         });            
    //     } catch(err) {
    //         console.error(err)
    //     }

    // });

    // function decreaseFontSize() {
    //     if (fontSize > 0.5) {
    //         setFontSize(fontSize - 0.25)
    //     }
    // }
    // function increaseFontSize() {
    //     setFontSize(fontSize + 0.25)

    // }
    // function defaultFontSize() {
    //     setFontSize(1)
    // }

    return (
        <div className="page-navigation level is-mobile">
            <div className="level-left">
                {prevLink && <Link className="page-nav-link level-item" to={prevLink}><FontAwesomeIcon style={{ marginRight: "0.5rem" }} title="Go to previous book page" className="arrow left-arrow" icon={faLongArrowAltLeft} /> <span>Previous Page</span></Link>}
            </div>
            <div className="level-right">
            {nextLink && <Link className="page-nav-link level-item" to={nextLink}><span>Next Page</span><FontAwesomeIcon style={{ marginLeft: "0.5rem" }} title="Go to next book page" className="arrow right-arrow" icon={faLongArrowAltRight} /></Link>}
            </div>
        </div>
    )
}

export default PageNavigation;