import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import TextSizer from '../components/TextSizer'
import PageNavigation from '../components/PageNavigation'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFont } from '@fortawesome/free-solid-svg-icons/faFont'
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'


export const BlogPostTemplate = ({
  content,
  contentComponent,
  date,
  tags,
  title,
  pageNumber,
  heading,
  helmet,
  prev,
  next
}) => {
  const PostContent = contentComponent || Content

  console.log(prev, next)
  return (
    <section className="section blog-section">
      {helmet || ''}
      <div className="container content">
        <div className="columns blog-columns">
          <div className="column is-10 is-offset-1">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
            Page {pageNumber} – {heading}
            </h1>
            <h2 className="date">Published on {date}</h2>
            <TextSizer />
            <PostContent className="post-content" content={content} />
            <PageNavigation 
              prevLink={prev && prev.fields.slug}
              nextLink={next && next.fields.slug}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const BlogPost = ({ data, pageContext }) => {
  const { markdownRemark: post } = data

  const {prev, next} = pageContext

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        date={post.frontmatter.date}
        helmet={
          <Helmet titleTemplate="%s">
            <title>{`Page ${post.frontmatter.pageNumber} – ${post.frontmatter.heading}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        heading={post.frontmatter.heading}
        pageNumber={post.frontmatter.pageNumber}
        prev={prev}
        next={next}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        pageNumber
        heading
      }
    }
  }
`
