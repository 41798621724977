import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFont } from '@fortawesome/free-solid-svg-icons/faFont'
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'

function TextSizer() {

    const [fontSize, setFontSize] = useState(1);

    useEffect(() => {
        try {
            var postParagraphs = document.getElementsByClassName("post-content")[0].querySelectorAll("p");
            // x.style.fontSize = fontSize + "rem"
            postParagraphs.forEach(paragraph => {
                paragraph.style.fontSize = fontSize + "rem"
            });            
        } catch(err) {
            console.error(err)
        }

    });

    function decreaseFontSize() {
        if (fontSize > 0.5) {
            setFontSize(fontSize - 0.25)
        }
    }
    function increaseFontSize() {
        setFontSize(fontSize + 0.25)

    }
    function defaultFontSize() {
        setFontSize(1)
    }

    return (
        <div className="text-sizer">
            <FontAwesomeIcon title="Decrease font size" className="text-sizer-box" onClick={decreaseFontSize} icon={faMinus} />
            <FontAwesomeIcon title="Reset font size" className="text-sizer-box" onClick={defaultFontSize} icon={faFont} />
            <FontAwesomeIcon title="Increase font size" className="text-sizer-box" onClick={increaseFontSize} icon={faPlus} />
            <span title="Font size" style={{marginLeft: "0.25rem", fontSize:"0.75rem", alignSelf: "center"}}>{fontSize * 16}px</span>
        </div>
    )
}

export default TextSizer;